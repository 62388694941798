<template>
    <div class="filter-box">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>

        <SuperviseAuditsFilterInputs :currentFilters="currentFilters" :activeFilters="activeFilters" @inputChange="(params) => updateCurrentFilters(params)" />

        <FilterActions
            :showClearFiltersButton="showClearFiltersButton"
            :clearFiltersCallback="resetFilters"
            :filterCallback="applyFilters"
            :changesDetected="changesDetected"
        />
    </div>
</template>

<script>
import FilterActions from '../../../ui/FilterActions.vue'
import SuperviseAuditsFilterInputs from './SuperviseAuditsFilterInputs.vue'
import { getListOfIds } from '../../../../../public/js/utils'

const defaultFilters = {
    sites: '',
    location: [],
    template: [],
    employees: [],
    type_date: 'week',
    score: [0, 100],
    // for custom dates:
    start_date: '',
    end_date: '',
    finalNote: ''
}

export default {
    name: 'FilterAudit',
    components: {
        FilterActions,
        SuperviseAuditsFilterInputs
    },
    props: {
        isDisabled: { type: Boolean, default: false },
        applyFilter: { type: Boolean, default: false },
        pagination: { type: Object, default: false },
        sortBy: { type: undefined, default: false },
        sortType: { type: undefined, default: false },
        changePagination: { type: Boolean, default: false },
        selectedView: { type: Object }
    },
    data() {
        return {
            currentFilters: structuredClone(defaultFilters),
            emptyFilters: structuredClone(defaultFilters),
            activeFilters: structuredClone(defaultFilters),

            // Filter variables
            changesDetected: false
        }
    },
    computed: {
        showClearFiltersButton() {
            return JSON.stringify(this.activeFilters) != JSON.stringify(this.emptyFilters)
        },

        resetFilterState() {
            return this.$store.getters['audits/getResetFiltersState']
        }
    },
    methods: {
        updateCurrentFilters(params) {
            const { inputName, value } = params

            const clonedFilters = structuredClone(this.currentFilters)

            if (inputName === 'type_date' && value !== 'custom') {
                clonedFilters.start_date = ''
                clonedFilters.end_date = ''
            }

            if (inputName === 'sites') {
                clonedFilters.locations = ''
                clonedFilters.groups = ''
            }

            if (value && Array.isArray(value)) {
                // update currentFilters values:
                clonedFilters[inputName] = [...value]
            } else {
                clonedFilters[inputName] = value
            }

            this.currentFilters = clonedFilters

            this.$store.commit('audits/setCurrentFilters', this.currentFilters)
        },

        // updateFilters() {
        //     this.$emit('applyFilters', this.allFilters)
        // },

        // parseDate(date) {
        //     // TODO IF NECESARY AN SPECIFIC DATE FORMAT
        //     return date
        // },
        getIdsList(filterName, defaultValue = '') {
            const self = this

            if (!filterName) {
                return
            }

            const filterList = self.currentFilters[filterName]

            if (filterList) {
                if (Array.isArray(filterList) && filterList.every((e) => typeof e === 'string')) {
                    return filterList
                }

                if (Object.values(filterList).length) {
                    const listOfIds = filterList.map((filter) => {
                        if (filter.id !== null) {
                            return filter.id
                        }
                    })

                    return JSON.stringify(listOfIds)
                }
            } else return defaultValue
        },

        transformFiltersForDB(filters) {
            const filtersForDB = {
                location: filters.location && filters.location.length > 0 ? JSON.stringify(getListOfIds(filters.location)) : '',
                status: filters.status && filters.status.length > 0 ? JSON.stringify(getListOfIds(filters.status)) : '',
                template: filters.template && filters.template.length > 0 ? JSON.stringify(getListOfIds(filters.template)) : '',
                employees: filters.employees && filters.employees.length > 0 ? JSON.stringify(getListOfIds(filters.employees)) : '',
                start_date: filters.type_date ? filters.type_date : 'week',
                end_date: null,
                score: filters.score != [0, 100] ? filters.score.join(',') : '0, 100',
                finalNote: filters.finalNote ? filters.finalNote : ''
            }

            const locations = this.currentFilters.locations ? this.currentFilters.locations : null
            const groups = this.currentFilters.groups ? this.currentFilters.groups : null

            if (locations) {
                filtersForDB.location = JSON.stringify(getListOfIds(locations))
                filtersForDB.groups = ''
            }

            if (groups) {
                filtersForDB.groups = JSON.stringify(getListOfIds(groups))
                filtersForDB.locations = ''
            }

            if (filters.groups) {
                filtersForDB.groups = JSON.stringify(getListOfIds(filters.groups))
            }

            if (filters.type_date === 'custom') {
                filtersForDB.start_date = filters.start_date ? moment(filters.start_date).format('YYYY-MM-DD') : null
                filtersForDB.end_date = filters.end_date ? moment(filters.end_date).format('YYYY-MM-DD') : null
            }

            return filtersForDB
        },

        applyFilters() {
            const amplitudeProps = {
                page: 'SuperviseAudits'
            }

            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }

            this.$amplitude.track('Uses of filters', {
                ...amplitudeProps,
                filters: this.currentFilters
            })
            //En caso de que se haya paginado con filtros aplicados, se resetea la paginacion pero no se hace 2 peticiones ANDY 1271
            if (this.resetFilterState) {
                this.$store.commit('audits/setResetFiltersState', false)
            }

            const self = this

            //Si entrar a un reporte y das para atras
            if (self.$route.params.noChangePageDatatable) {
                self.$route.params.noChangePageDatatable = false
                return
            }

            const iApplyFilters = self.changesDetected ? 1 : 0
            let pagenum = self.pagination.page

            if (iApplyFilters) {
                self.$emit('resetPagination')
                pagenum = 1
            }

            self.$overlay.loading()

            self.changesDetected = false

            if (self.$store.getters['audits/getCanLoadList']) {
                const filters = self.transformFiltersForDB(self.currentFilters)

                const loadListParams = {
                    applyFilters: iApplyFilters,
                    pagenum: pagenum,
                    number_items: self.pagination.itemsPerPage,
                    sortBy: self.sortBy,
                    sortType: self.sortType,
                    ...filters
                }

                self.$store.dispatch('audits/loadList', loadListParams).then(() => {
                    self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))
                    self.$store.commit('audits/setFiltersActive', self.activeFilters)

                    self.$store.commit('reports/setReportsToExportAuditsFilters', {
                        sortType: this.sortType,
                        sortBy: this.sortBy.join(''),
                        ...filters
                    })

                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                    self.$emit('filtersApplied')

                    if (JSON.stringify(self.activeFilters) != JSON.stringify(self.emptyFilters)) {
                        self.$emit('reset', true)
                    } else {
                        self.$emit('reset', false)
                    }
                })
            } else {
                self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))
                self.$overlay.hide()
                self.$emit('resetPageDatatable')

                if (JSON.stringify(self.activeFilters) != JSON.stringify(self.emptyFilters)) {
                    self.$emit('reset', true)
                } else {
                    self.$emit('reset', false)
                }
            }
        },

        resetFilters() {
            this.$emit('resetPagination')

            const iApplyFilters = 0
            var self = this

            this.$overlay.loading()
            this.$store.commit('audits/setResetFiltersState', true)

            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }

            this.$store.commit('audits/setFiltersActive', this.currentFilters)

            this.changesDetected = false

            self.$emit('on-reset-filters')

            this.$overlay.hide()
        },

        handleChangePagination(page) {
            let self = this

            self.$overlay.loading()

            let iApplyFilters = self.changesDetected ? 1 : 0
            let pagenum = page ? page : self.pagination.page

            if (this.resetFilterState) {
                this.$store.commit('audits/setResetFiltersState', false)
                return
            }

            const filters = self.transformFiltersForDB(self.activeFilters)

            const loadListParams = {
                applyFilters: iApplyFilters,
                pagenum: pagenum,
                number_items: self.pagination.itemsPerPage,
                sortBy: self.sortBy,
                sortType: self.sortType,
                ...filters
            }

            self.$store.dispatch('audits/loadList', loadListParams).then(() => {
                self.$overlay.hide()
                self.$emit('resetPageDatatable')

                let reset = JSON.stringify(self.activeFilters) != JSON.stringify(self.emptyFilters)
                self.$emit('reset', reset)
            })
        }
    },
    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.activeFilters)) {
                    this.changesDetected = true
                } else {
                    this.changesDetected = false
                }
            },
            deep: true
        },
        sortType: {
            handler: function (val, oldVal) {
                if (oldVal !== false && oldVal !== undefined) {
                    this.handleChangePagination()
                }
            }
        },

        selectedView(newSelectedView, oldSelectedView) {
            if (!newSelectedView) return

            this.currentFilters = structuredClone(newSelectedView.data)
            this.$store.commit('audits/setCurrentFilters', this.currentFilters)

            this.applyFilters()
        }
    },
    mounted() {
        this.activeFilters = {
            ...this.$store.getters['audits/getFiltersActive']
        }
        this.currentFilters = {
            ...this.$store.getters['audits/getFiltersActive']
        }
    },
    created() {
        if (this.applyFilter) {
            this.currentFilters = {
                ...this.$store.getters['audits/getFiltersActive']
            }
        }
    }
}
</script>

<style lang="scss">
// Busca en assets :^)
</style>
